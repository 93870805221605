
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class GlobalConstant {
  public static version = "2.0.2"
  // local test

}






