import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { Router } from '@angular/router';
@Component({
  selector: 'app-ms-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarMsComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;


  // username = sessionStorage.getItem('email');
  username: string;

  ngAfterViewInit() {
    this.username = sessionStorage.getItem('name');
  }

  private _mobileQueryListener: () => void;
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }

  subclickEvent() {
    this.status = true;
  }
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private router: Router
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  changePassword() {
    return this.router.navigate(['/home/changepassword']);
  }
  signOut() {
    var x;
    if (confirm("Are you sure want to sign out?") == true) {
      // x = "You pressed OK!";
      localStorage.clear();
      sessionStorage.clear();
      // this.router.navigate(['/ms/login']);
      location.href = '/ms/login';

    } else {
      x = "You pressed Cancel!";
    }
  }
}
