import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortenWord'
})
export class ShortenWordPipe implements PipeTransform {
    transform(value: string, length: any): string {
        if (value == null) return value
        if (length == null) {
          return value
        } else {
          if (value.length <= length) {
            return value;
          } else if (value.length >= length) {
            return value.substring(0, length - 3) + '... ';
          }
        }
      }
    }