import { NgModule, OnInit } from "@angular/core";
import { FormatDateTimePipe } from "./core/_pipe/date_time_format.pipe";
import { ShortNumberPipe } from "./core/_pipe/short-number.pipe";
import { ShortenWordPipe } from "./core/_pipe/shorten-word.pipe";



@NgModule({
    imports: [],
    declarations: [
        FormatDateTimePipe,
        ShortenWordPipe,
        ShortNumberPipe,

    ],
    exports: [
        FormatDateTimePipe,
        ShortenWordPipe,
        ShortNumberPipe,

    ],
    providers: [
        FormatDateTimePipe,
        ShortenWordPipe,
        ShortNumberPipe,
    ]

})

export class PipeModule{
    constructor(
        public shortenWord: ShortenWordPipe,
        public formatDateTime : FormatDateTimePipe,
        public shortNumberPipe : ShortNumberPipe,
        ){
    }

}