import { Component, OnInit } from '@angular/core';
import { GlobalConstant } from '../../shared/global';


@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {
  version: string;
  constructor() { 
    this.version = GlobalConstant.version;
  }

  ngOnInit(): void {
  }

}
