import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { AccumulationChartModule } from '@syncfusion/ej2-angular-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { FullMsComponent } from './module/full/full.component';
import { AppSidebarMsComponent } from './module/full/sidebar/sidebar.component';
import { AppHeaderMsComponent } from './module/full/header/header.component';
import { Globals } from './shared/common';
import { PipeModule } from './app-pipes.module';
import { GlobalService } from './core/_services/global.service';
import { VersionComponent } from './module/version/version.component';
import { ChartsModule } from 'ng2-charts';
import { ChartModuleComponent } from './shared/chart-module/chart-module.component';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';



export function appInit(globalService: GlobalService) {
  return () => globalService.getEnv();
}



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    FullMsComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    AppSidebarComponent,
    AppSidebarMsComponent,
    AppHeaderMsComponent,
    VersionComponent,
    // TreeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    AccumulationChartModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot(AppRoutes),
    PipeModule,
    NgxEchartsModule.forRoot({
      echarts
  }),
  ],
  exports:[
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInit, multi: true, deps: [GlobalService] },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    Globals,
    // ArraySortPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
