import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: '',
    name: 'RAC',
    type: 'saperator',
    icon: 'av_timer'
  },
  {
    state: 'home',
    name: 'Dashboard',
    type: 'sub',
    icon: 'av_timer',
    page: 'bod',
    children: [
      { state: 'home1', name: 'Dashboard 1', type: 'link' },
      { state: 'bod', name: 'Dashboard 2', type: 'link' },
      { state: 'bod-test', name: 'Dashboard 3', type: 'link' },
      // { state: 'dashboard5', name: 'Dashboard 4', type: 'link' },
      { state: 'projectmanager', name: 'P.I.C', type: 'link' },
    ]
  },

  {
    state: 'home',
    name: 'Project',
    type: 'sub',
    icon: 'apps',
    page: 'project',
    children: [
      { state: 'addproject', name: 'New project', type: 'link' },
      { state: 'allproject', name: 'Project list', type: 'link' },
      // { state: 'project', name: 'View projects', type: 'link' },
      // { state: 'addissue', name: 'Add issues', type: 'link' }
    ]
  },


];


const MENUITEMSADMIN = [
  {
    state: '',
    name: 'RAC',
    type: 'saperator',
    icon: 'av_timer'
  },
  {
    state: 'home',
    name: 'Dashboard',
    type: 'sub',
    icon: 'av_timer',
    page: 'bod',
    children: [
      { state: 'home1', name: 'Dashboard', type: 'link' },
      { state: 'projectmanager', name: 'P.I.C', type: 'link' },
      { state: 'bod', name: 'Board Dashboard', type: 'link' },


    ]
  },

  {
    state: 'home',
    name: 'Project',
    type: 'sub',
    icon: 'apps',
    page: 'project',
    children: [
      { state: 'addproject', name: 'New project', type: 'link' },
      { state: 'projectlist', name: 'Project list', type: 'link' },
      // { state: 'addissue', name: 'Add issues', type: 'link' }
    ]
  },
  // {
  //   state: 'ms',
  //   name: 'User',
  //   type: 'sub',
  //   icon: 'perm_contact_calendar',
  //   children: [
  //     { state: 'register', name: 'Add user', type: 'link' },

  //     // { state: 'addissue', name: 'Add issues', type: 'link' }
  //   ]
  // },
  {
    state: 'home',
    name: 'Settings',
    type: 'sub',
    icon: 'insert_drive_file',
    page: 'settings',
    children: [
      // { state: 'addcategory', name: 'New category', type: 'link' },
      { state: 'addpelaksana', name: 'New executor agency', type: 'link' },
      { state: 'adduser', name: 'New user', type: 'link' },
      { state: 'adddepartment', name: 'New department', type: 'link' }
    ]
  }

];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
  if(sessionStorage.getItem('check') == 'true'){
    return MENUITEMSADMIN;

  }else return MENUITEMS;

  }
}
