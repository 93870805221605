import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class GlobalService {
    public serverURL: string;
    public testServerURL:string;
    public currency : string;
    public dashboard4RACOwn: string;
    constructor(private http: HttpClient) {
    }

    getEnv() :Promise<any>  {
        const promise = this.http.get('./assets/env.json')
          .toPromise()
          .then(data => {
            Object.assign(this, data);
            this.serverURL = data["server-url"];
            this.testServerURL = data["server-url-test"];
            this.currency = data["currency"];
            this.dashboard4RACOwn = data["dashboard4RACOwn"];
            return data;
          });

        return promise;
    }

}
