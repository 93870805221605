import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({ name: 'formatDateTime' })
export class FormatDateTimePipe implements PipeTransform {
  transform(value: any , type:string): string {
    if(value == null || value == ''){
        return '-'
      }else{
        switch (type) {
          case "dateTime":
              value = moment(value).format('D MMM YYYY, h:mm a');
              return value;

          case "date":
              value = moment(value).format('D MMM YYYY');
              return value

          case "table":
              return moment(value).format('D MMM YYYY, h:mm a');

          case "minute":
              return moment(value).format('h:mm a');

          case "time":
              return moment(value, 'HH:mm').format('h:mm a');

          case "monthYear":
              return moment(value).format('MMM YYYY');

          case "D":
              return moment(value).format('D');

          case "DD":
              return moment(value).format('DD');

          case "MM":
              return moment(value).format('MM');

          case "YYYY":
              return moment(value).format('YYYY');

          case "hh":
              return moment(value).format('hh');

          case "mm":
              return moment(value).format('mm');

          case "min":
              return moment(value, "mm:ss").format('mm')

          case "A":
              return moment(value).format('A');

          case "dateOnly":
              return moment(value, 'YYYY-MM-DD').format('D MMM YYYY');

          case "export": {
                return moment(value).format('D MMM YYYY');
          }

          default:
              return value
        }
      }
  }

}